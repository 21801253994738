import React, { useState } from "react"
import apiClient from "../../../js/apiclient"
import * as styles from "./signupform.module.css"
import ErrorsList from "./errlist"
import { VendorsAuthBox } from "../../vendors/vendors-auth-box"

const SignUpForm = ({successfulRegistrationHandler}) => {
  const [newUser, setNewUser] = useState({
    fullName: "",
    email: "",
  })
  const [errors, setErrors] = useState({})
  const [isPending, setIsPending] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("Submit SignUp form")
    setIsPending(true)
    apiClient.signUp(newUser).then(response => {
      setIsPending(false)
      if (response.status !== 200) {
        response.json().then(responseErrors => {
          const rawRespErrors = [...responseErrors]
          const fieldErrors = rawRespErrors.reduce((acc, error) => {
            if (acc[error.field] !== undefined) {
              acc[error.field].push(error.error)
            } else {
              acc[error.field] = [error.error]
            }

            return acc
          }, {})
          console.log(fieldErrors)
          setErrors(fieldErrors)
        })
      } else {
        console.log("Sign up response: ", response)
        successfulRegistrationHandler(newUser)
      }
    })
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    delete errors[name]

    const updatedNewUser = Object.assign({}, newUser) //shallow copy
    updatedNewUser[name] = value

    setNewUser(updatedNewUser)
  }

  return (
    <div id="signupquick" className={styles.quicksignup}>
      <h3>Create an account</h3>
      <form onSubmit={handleSubmit}>
        <label>
          <span>*</span> Full name:
          {errors["fullName"] !== undefined && <ErrorsList errors={errors["fullName"]} />}
          <input
            disabled={isPending}
            name="fullName"
            type="text"
            placeholder="full name"
            onChange={handleInputChange} />
        </label>
        <div style={{
          height: "15px"
        }}></div>
        <label htmlFor="email">
          <span>*</span> Email:
          {errors["email"] !== undefined && <ErrorsList errors={errors["email"]} />}
          <input
            disabled={isPending}
            name="email"
            type="email"
            placeholder="email"
            onChange={handleInputChange} />
        </label>
        <br />
        <div className={styles.terms}>
          I accept the <a href="/legal/terms-of-use">Terms of Service</a> and give my consent to Dockovpn to process my
          personal data for
          the services outlined in the <a href="/legal/privacy-policy">Privacy Policy</a>
        </div>
        <input type="submit" value="Sign Up" disabled={isPending} />
      </form>
      <VendorsAuthBox />
      <div className={styles.suiteBlock}></div>
    </div>
  )
}

export default SignUpForm