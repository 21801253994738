import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import LoginLayout from "../components/layouts/loginlayout"
import util from "../js/util"
import Loader from "../components/common/loader/loader"
import apiClient from "../js/apiclient"
import BaseLayout from "../components/layouts/baselayout"
import SignUpForm from "../components/forms/signupform/signupform"
import BigMessage from "../components/common/bigmessage/bigmessage"
import { useDispatch } from "react-redux"
import { setVendorConfigs } from "../state/vendorConfigSlice"

const UnregisteredView = ({successfulRegistrationHandler}) => {
  return (
    <LoginLayout>
      <SignUpForm successfulRegistrationHandler={successfulRegistrationHandler}/>
      <div style={{
        width: 'fit-content',
        margin: '0 auto',
        textAlign: 'center'
      }}>Already have an account? <a href="/">Sign In</a></div>
    </LoginLayout>
  )
}

const RegistrationSuccess = ({email}) => {
  return (
    <LoginLayout>
      <BigMessage btn={{title: "Sign In", page: "/"}}>You request has been processed. If you entered correct information, we will send your login details to <strong>{email}</strong>. Please check
        your mailbox.
      </BigMessage>
    </LoginLayout>
  )
}

const Signup = () => {
  const [newUser, setNewUser] = useState(null)
  const [view, setView] = useState(null)
  const initialized = view !== null
  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialized) {
      apiClient.currentLogin().then(response => {
        if (response.status !== 200) {
          if (response.status === 403) {
            apiClient.getVendorConfigs().then(response => {
              response.json().then(configs => {
                dispatch(setVendorConfigs(configs))
                setView("unregistered")
              })
            })
          }
        } else {
          util.navigateToPage("/dashboard")
        }
      })
    }})

  const handleSuccessfulRegistration = (user) => {
    setNewUser(user)
    setView("registered")
  }

  let content =  <Loader />

  if (view === "unregistered") {
    content = <UnregisteredView successfulRegistrationHandler={handleSuccessfulRegistration}/>
  } else if (view === "registered") {
    content = <RegistrationSuccess userName={newUser.fullName} email={newUser.email}/>
  }

  return (
    <>
      <BaseLayout />
      {content}
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Signup">
  <script src="https://accounts.google.com/gsi/client"/>
</Seo>

export default Signup